<template>
  <section class="relative">

    <div class="relative max-w-6xl mx-auto px-4 sm:px-6">
      <div class="pt-32 pb-12 md:pt-40 md:pb-4">

        <div class="max-w-3xl" data-aos="fade-down">
          <article>

            <header>
              <!-- Title and excerpt -->
              <div class="text-center md:text-left">
                  <h1 class="h1 font-red-hat-display mb-4">Terms and Conditions</h1>

              </div>

            </header>
          </article>
        </div>

      </div>
    </div>

  </section>
</template>

<script>
export default {
  name: 'HeroTerms',
}
</script>